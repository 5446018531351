import React from "react";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ProvideGlobalState } from './src/contexts/global-state-provider';
import { ProvideNotifications } from './src/contexts/notifications-provider';
import { ProvideBreadcrumbs } from './src/contexts/breadcrumbs-provider';
import './src/styles/global.css';
// Adding inter DIRECTLY to the project with npm install @fontsource/inter,
// see https://www.gatsbyjs.com/docs/how-to/styling/using-web-fonts/
// import '@fontsource/inter/400.css'; // default inter
import '@fontsource/inter/variable-full.css'; // all other variants

// Load up stripe exactly once.
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY);

// Wrap our root element with any providers needed.
export const wrapRootElement = ({ element }) => {
  return (
    <Elements stripe={stripePromise}>
      <ProvideGlobalState>
        <ProvideNotifications>
          <ProvideBreadcrumbs>
            {element}
          </ProvideBreadcrumbs>
        </ProvideNotifications>
      </ProvideGlobalState>
    </Elements>
  );
};



// const React = require("react");

// const { Elements } = require('@stripe/react-stripe-js');
// const { loadStripe } = require('@stripe/stripe-js');
// const { ProvideGlobalState } = require('./src/contexts/global-state-provider');
// const { ProvideNotifications } = require('./src/contexts/notifications-provider');
// const { ProvideBreadcrumbs } = require('./src/contexts/breadcrumbs-provider');
// require('./src/styles/global.css');
// // Adding inter DIRECTLY to the project with npm install @fontsource/inter,
// // see https://www.gatsbyjs.com/docs/how-to/styling/using-web-fonts/
// // import '@fontsource/inter/400.css'; // default inter
// require('@fontsource/inter/variable-full.css'); // all other variants

// // Load up stripe exactly once.
// const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY);

// // Wrap our root element with any providers needed.
// exports.wrapRootElement = ({ element }) => {
//   return (
//     <Elements stripe={stripePromise}>
//       <ProvideGlobalState>
//         <ProvideNotifications>
//           <ProvideBreadcrumbs>
//             {element}
//           </ProvideBreadcrumbs>
//         </ProvideNotifications>
//       </ProvideGlobalState>
//     </Elements>
//   );
// };
