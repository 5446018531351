import React, { useState, createContext, useContext } from 'react';

const initialState = {};
const GlobalStateContext = createContext(initialState);


export function ProvideGlobalState({ children }) {
  const [globalState, setGlobalState] = useState(initialState);
  const globalStateControls = { globalState, setGlobalState };
  return <GlobalStateContext.Provider value={globalStateControls}>{children}</GlobalStateContext.Provider>;
}

export const useGlobalState = () => {
  return useContext(GlobalStateContext);
};
