import React, { useState, createContext, useContext } from 'react';

const BreadcrumbsContext = createContext({
  breadcrumbs: [],
  setBreadcrumbs: null,
  isActive: false,
});


export function ProvideBreadcrumbs({ children }) {
  const [crumbs, setCrumbs] = useState([]);
  const [hrefs, setHrefs] = useState([]);

  const setBreadcrumbs = (newBreadcrumbs) => {
    const newCrumbs = [];
    const newHrefs = [];
    newBreadcrumbs.forEach((item) => {
      const { href } = item;
      newHrefs.push(href);
      newCrumbs.push(item);
    });
    setCrumbs(newCrumbs);
    setHrefs(newHrefs);
  };

  const isActive = (href) => {
    return hrefs.indexOf(href) !== -1;
  }


  const state = {
    breadcrumbs: crumbs,
    setBreadcrumbs,
    isActive,
  };
  return <BreadcrumbsContext.Provider value={state}>{children}</BreadcrumbsContext.Provider>;
}

export const useBreadcrumbs = () => {
  return useContext(BreadcrumbsContext);
};
