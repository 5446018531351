import React, { useState, createContext, useContext } from 'react';

const NotificationsContext = createContext({
  notifications: [],
  addNotification: null,
  shiftNotification: null,
});


export function ProvideNotifications({ children }) {
  const [notifications, setNotifications] = useState([]);

  const addNotification = (notification) => {
    setNotifications([...notifications, notification]);
  };

  const shiftNotification = () => {
    if (notifications.length) {
      const newNotifications = [...notifications];
      newNotifications.shift();
      setNotifications(newNotifications);
    }
  }


  const state = {
    notifications,
    addNotification,
    shiftNotification,
  };
  return <NotificationsContext.Provider value={state}>{children}</NotificationsContext.Provider>;
}

export const useNotifications = () => {
  return useContext(NotificationsContext);
};
